import React from "react";
import { SignIn } from "aws-amplify-react";
//import '../css/custom.css'

export default class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"]
  }

  showComponent(theme) {
      return (
        <div className="Form__formContainer___cu04J">
          <div className="Form__formSection___3tqxz">
              <div className="Section__sectionHeader___13iO4"><span>Sign in to your account</span></div>
              <form>
                  <div className="Section__sectionBody___3DCrX">
                      <div className="Form__formField___2DWhT">
                          <div className="Input__inputLabel___nQolz">
                          <label
                            className="block text-grey-darker text-sm font-bold mb-2"
                            htmlFor="username"
                          >
                            Email *
                          </label>
                          
                          </div>
                          <input className="Input__input___2Sh1s"
                            id="username"
                            key="username"
                            name="username"
                            onChange={this.handleInputChange}
                            type="email"
                            placeholder="Email address"
                            
                          />
                      </div>
                      <div className="Form__formField___2DWhT">
                          <div className="mb-6">
                            <label
                              className="block text-grey-darker text-sm font-bold mb-2"
                              htmlFor="password"
                            >
                              Password
                            </label>
                          </div>
                          <input className="Input__input___2Sh1s"
                            id="password"
                            key="password"
                            name="password"
                            onChange={this.handleInputChange}
                            type="password"
                            placeholder="Enter your password"
                            
                          />
                          <div className="Hint__hint___3Hrww">Forget your password? <a href="/#" data-test="sign-in-forgot-password-link" className="Anchor__a___3JUCG" onClick={() => super.changeState("forgotPassword")}>
                              Reset password</a>
                          </div>
                      </div>
                  </div>
                  <div className="Section__sectionFooter___1QlDn">
                      <span className="Section__sectionFooterPrimaryContent___36Gb1">
                          <button type="submit" className="Button__button___1FrBC" style={{backgroundColor: 'green', borderColor: 'red'}} 
                          onClick={(event) => super.signIn(event)}>Sign In
                          </button>
                      </span>
                      <span className="Section__sectionFooterSecondaryContent___3cjOa">No account? 
                          <a className="Anchor__a___3JUCG" href="/#"
                          onClick={() => super.changeState("signUp")}> Create account</a>
                      </span>
                  </div>
              </form>
          </div>
      </div>
      );
  }
}